import React, { useState, useRef } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import CardGrid from '../../../../commons/card/card-grid';
import IconChevron from '../../../../commons/icons/chevron';
import Section from '../../../../commons/section';
import withTracker from '../../../../commons/with-tracker';

const MARGIN = 20;
function CategoriesMobile({ title, type, elements, view_more, view_less, view_more_action }) {
  const btnRef = useRef();
  const [categories, setCategories] = useState(elements.slice(0, 9));
  const [showMore, setShowMore] = useState(true);
  const handleOnClick = () => {
    if (showMore) {
      setCategories(elements);
      setShowMore(prevShowMore => !prevShowMore);
    } else {
      if (btnRef.current) {
        const { offsetTop } = btnRef.current;
        window.scrollTo({
          top: offsetTop - MARGIN,
          behavior: 'smooth',
        });
      }
      setCategories(elements.slice(0, 9));
      setShowMore(prevShowMore => !prevShowMore);
    }
  };
  return (
    <Section type={type}>
      <div ref={btnRef} className="section-header">
        <h2>{title}</h2>
      </div>
      <div className="row container">
        <div className="wrapper">
          {categories.map(item => (
            <div key={item.title} className="category-wrapper">
              <CardGrid key={item.id} {...{ item }} />
            </div>
          ))}
        </div>
      </div>
      {view_more && (
        // eslint-disable-next-line
        <p onClick={handleOnClick} className="section-button section-button__view-more">
          {showMore ? view_more.label : view_less.label}
          <IconChevron className={showMore ? 'icon-chevron--more' : 'icon-chevron--less'} aria-hidden="true" />
        </p>
      )}
      {!view_more && view_more_action && (
        <a href={view_more_action.target} target={view_more_action.htmlTarget} className="section-button">
          {view_more_action.label}
          {view_more_action.accesibilityText && <span className="clipped">{view_more_action.accesibilityText}</span>}
          <IconChevron aria-hidden="true" />
        </a>
      )}
    </Section>
  );
}

CategoriesMobile.propTypes = {
  elements: arrayOf(
    shape({
      picture: shape({
        src: string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  title: string.isRequired,
  type: string.isRequired,
  view_more: shape({
    label: string,
  }),
  view_less: shape({
    label: string,
  }),
  view_more_action: shape({
    label: string,
    target: string,
    htmlTarget: string,
    accesibilityText: string,
  }),
};

export default withTracker(CategoriesMobile);
